@font-face {
  font-family: "Inter UI";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-UI-Regular.woff") format("woff");
}

@font-face {
  font-family: "Inter UI";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-UI-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Inter UI";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-UI-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Inter UI";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-UI-ExtraBold.woff2") format("woff2");
}
