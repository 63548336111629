.mdc-button:not(.mdc-button--unelevated):not(:disabled) .mdc-button__label {
  @apply dark:text-blue-700;
}

.mdc-button--unelevated {
  @apply shadow px-2;

  &:disabled {
    @apply shadow-none;
  }
}

// TODO: Change to cadet-blue
.mdc-button:not(.mdc-button--unelevated) {
  &:not(:disabled) .mdc-button__label {
    @apply text-black dark:text-white text-opacity-95;
  }
  .mdc-button__ripple::before,
  .mdc-button__ripple::after {
    @apply bg-black dark:bg-white;
  }
}

.mdc-button--unelevated {
  .mdc-button__ripple::before,
  .mdc-button__ripple::after {
    @apply bg-white;
  }
}
