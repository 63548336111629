.create-todo-wrapper {
  .create-todo-icon {
    @apply text-cadet-blue-200 dark:text-shark-500;
  }

  &:hover .create-todo-icon,
  &.focused .create-todo-icon {
    @apply text-cadet-blue-400 dark:text-cadet-blue-500;
  }

  .create-todo-textarea::placeholder {
    @apply text-cadet-blue-200 dark:text-shark-500 transition-all;
  }

  &:hover .create-todo-textarea::placeholder,
  .create-todo-textarea:focus::placeholder {
    @apply text-cadet-blue-400 dark:text-cadet-blue-500;
  }
}
