.Wrapper {
  display: block;
  padding-left: var(--spacing);

  .TreeItem {
    > div {
      @apply -m-px;
    }
  }

  &.clone {
    @apply pl-0 bg-transparent;

    .TreeItem {
      @apply rounded;

      // > div {
      //   @apply;
      // }
    }
  }

  &.ghost {
    .TreeItem {
      button:not(.collapseBtn),
      [role="button"]:not(.collapseBtn) {
        @apply bg-transparent;

        > * {
          @apply opacity-0;
        }
      }
    }
  }
}

// .TreeItem {
//   button,
//   [role="button"] {
//     @apply cursor-default;
//   }
// }

.Text {
  flex-grow: 1;
  padding-left: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #fff;

  @apply text-xs font-semibold bg-cornflower-blue;
}

.disableInteraction {
  pointer-events: none;
}

.disableSelection,
.clone {
  .Text,
  .Count {
    user-select: none;
    -webkit-user-select: none;
  }
}
