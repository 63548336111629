// @use "material-components-web/material-components-web";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./static/fonts/fonts.css";
@import "./static/bright-theme.scss";

@layer base {
  @media (prefers-color-scheme: dark) {
    :root {
      color-scheme: dark;
    }

    ::-webkit-scrollbar {
      @apply w-2;
    }

    ::-webkit-scrollbar-track {
      @apply dark:bg-shark-dark;
    }

    ::-webkit-scrollbar-thumb {
      @apply bg-shark-300/50 rounded-full;
    }

    ::-webkit-scrollbar-thumb:hover {
      @apply bg-shark-300/60;
    }
  }

  html,
  body,
  #root {
    @apply h-full w-full;
  }

  html {
    @apply bg-white dark:bg-shark;
  }

  .textColor {
    @apply text-cadet-blue-800 dark:text-cadet-blue-dark-text-color;
  }

  .textColorHover {
    @apply hover:text-cadet-blue-800 hover:dark:text-cadet-blue-dark-text-color;
  }

  .textColorFocus {
    @apply focus:text-cadet-blue-800 focus:dark:text-cadet-blue-dark-text-color;
  }

  .disabledTextColor {
    @apply text-cadet-blue-400 dark:text-cadet-blue-500;
  }

  body {
    @include mdc-bright-theme;
    @extend .textColor;
    @apply text-xs m-0 p-0 block box-border antialiased select-none;
  }

  h1 {
    @apply text-2xl font-semibold;
  }

  p,
  span {
    @apply font-medium;
  }

  .prose {
    h1,
    p,
    time,
    li {
      @apply leading-4 my-2;
    }

    h1 {
      @apply text-sm font-semibold;
    }

    time {
      @apply opacity-75  font-normal font-mono;
    }

    p,
    li {
      @apply font-medium;
    }

    ul {
      @apply list-disc my-3;

      li {
        @apply list-item ml-6;
      }
    }
  }

  .material-icons svg {
    @apply h-6 w-6;
  }

  .text-xxs {
    font-size: 0.625rem /* 12px */ !important;
    line-height: 0.875rem /* 16px */ !important;
  }

  button,
  [role="button"] {
    @apply rounded transition-colors outline-none ring-0 focus-visible:outline-none;
  }

  textarea {
    @apply rounded resize-none select-text transition-colors bg-transparent outline-none ring-0  focus-visible:outline-none border-none focus:ring-0;
  }

  hr {
    @apply my-0.5 border-0;

    &:not(.big) {
      @apply h-px bg-shark-light-300 dark:bg-shark-400;
    }

    &.big {
      @apply h-0.5 bg-shark-light-200 dark:bg-shark-600;
    }
  }

  .open {
    > .ripple:not([disabled]):not(.disabled),
    .todo-item.ripple {
      @apply bg-shark-light-200  dark:bg-shark-600;
    }

    > .ripple-menu:not([disabled]):not(.disabled) {
      @apply bg-shark-light-200 dark:bg-shark-500;
    }
  }

  .ripple:not([disabled]):not(.disabled) {
    @extend .highlight;
    @apply hover:bg-shark-light-200 focus:bg-shark-light-200 active:bg-shark-light-300 hover:dark:bg-shark-600 focus:dark:bg-shark-600 active:dark:bg-shark-500 transition-colors;
  }

  .ripple-active {
    @apply bg-shark-light-200 dark:bg-shark-600;
  }

  .ripple-filled:not([disabled]):not(.disabled) {
    @apply hover:bg-shark-light-200 focus:bg-shark-light-200 active:bg-shark-light-300 hover:dark:bg-shark-400 focus:dark:bg-shark-400 active:dark:bg-shark-300 transition-colors;
  }

  .ripple-menu:not([disabled]):not(.disabled) {
    @apply hover:bg-shark-light-200 focus:bg-shark-light-200 active:bg-shark-light-300 hover:dark:bg-shark-500 focus:dark:bg-shark-500 active:dark:bg-shark-400 transition-colors;
  }

  .highlight:not([disabled]):not(.disabled) {
    @apply transition-colors border border-transparent;

    &:focus,
    &:focus-visible {
      --tw-ring-offset-width: 1px;
      --tw-ring-offset-color: #60a5fa;
      @apply outline-offset-1 outline-blue-200 dark:outline-blue-800 outline-2 ring-0 border-blue-800 dark:border-blue-400;
    }
  }

  @media (prefers-color-scheme: dark) {
    .ripple:not([disabled]):not(.disabled):focus,
    .ripple:not([disabled]):not(.disabled):focus-visible,
    .radio:focus,
    .radio:checked:focus {
      --tw-ring-offset-color: #1e40af !important;
    }
  }
}

@layer components {
  .surface {
    @apply shadow-lg backdrop-blur-md bg-white dark:bg-shark-600 z-50;
  }

  .tooltip,
  .menu {
    @extend .surface;
    @apply rounded border border-shark-light-500 dark:border-shark-400;
  }

  .parent {
    .hidden-child {
      @apply transition-opacity opacity-0;
    }

    &:hover .hidden-child {
      @apply opacity-100;
    }
  }

  .shortcuts {
    @apply inline-block font-semibold whitespace-nowrap ml-4;
  }

  .text-input {
    @apply transition-colors rounded my-2 py-1.5 px-3 text-xs border-shark-light-300 hover:border-shark-light-500 dark:border-shark-400 w-full bg-transparent;

    &:focus,
    &:focus-visible {
      @apply border-blue-500 ring-0 outline-none;
    }
  }

  .radio {
    @apply transition-colors focus:border-blue-500 border;
  }

  .radio:not(:checked) {
    @extend .ripple-menu;
    @apply bg-transparent border-shark-light-500 dark:border-shark-300;
  }

  .radio:checked {
    @apply bg-blue-600 hover:bg-blue-500 border-blue-600 hover:border-blue-400;
  }

  .radio:focus,
  .radio:checked:focus {
    --tw-ring-offset-width: 1px;
    --tw-ring-offset-color: #60a5fa;
    @apply outline-offset-1 outline-blue-200 dark:outline-blue-800  outline-2 ring-0 border-blue-800 dark:border-blue-300;
  }

  .drag-overlay {
    @apply rounded;

    > div {
      @apply m-0;
    }

    .clone {
      @apply relative rounded shadow-xl;

      button,
      [role="button"] {
        @apply cursor-grabbing bg-shark-light-300 dark:bg-shark-500 m-px;
      }
    }
  }

  .ghost {
    .checkbox {
      @apply opacity-0;
    }
  }

  .loading-logo {
    -webkit-animation: sk-bounce 5s infinite ease-in-out;
    animation: sk-bounce 5s infinite ease-in-out;
  }

  @-webkit-keyframes sk-bounce {
    50%,
    100% {
      -webkit-transform: scale(0.8);
    }

    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
    }

    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
}
